import { Stack, Image } from "react-bootstrap";
import styles from "../styles.module.scss";
import Rating from "components/Shared/Rating/Rating";
import { DefaultMomentDateFormat } from "core/Mixins/MomentFormats";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SearchItem = (props: any) => {
  const { newsImage, summary, date, onClick, tag } = props;
  const { t, i18n } = useTranslation("home");

  return (
    <Stack
      direction="horizontal"
      className={styles.itemContainer}
      gap={2}
      onClick={onClick}
    >
      <div className={styles.itemImgBox}>
        <Image className={styles.itemImg} src={newsImage} />
      </div>

      <div className={`${styles.itemContent} w-100 px-4`}>
        <Stack direction="horizontal" gap={3}>
          {tag && <div className={styles.tag}>{tag}</div>}
          <p className="mb-2">
            {i18n.language === "ar" && t("day")} {DefaultMomentDateFormat(date)}{" "}
          </p>
        </Stack>

        {/* <Link to={`/news/${id}`} className="text-decoration-none"> */}
        <h1>{summary}</h1>
        {/* </Link> */}
      </div>
    </Stack>
  );
};

export default SearchItem;
