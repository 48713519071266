import { ReactNode } from "react";

import Container from "react-bootstrap/Container";

import AppBreadcrumb from "../../Shared/AppBreadcrumb/AppBreadcrumb";
import NavOne from "../../NavOne/NavOne";
import AppNavbar from "../../Shared/AppNavbar/AppNavbar";

interface IBreadCrumb {
  id: number;
  name: string;
  href: string;
}

interface IHeaderProps {
  title?: string;
  children?: ReactNode;
  className?: string;
  hasBreadCrumbs?: boolean;
  breadCrumbs?: IBreadCrumb[];
}

const Header = ({
  title,
  children,
  className,
  hasBreadCrumbs = false,
  breadCrumbs = [
    {
      id: 1,
      name: "HeaderTwo.main",
      href: "/",
    },

    {
      id: 2,
      name: "HeaderTwo.webinars",
      href: "/webinars",
    },
  ],
}: IHeaderProps) => {
  console.log({breadCrumbs});
  return (
    <div className={`bg-blue-with-circle ${className}`}>
      <NavOne />
      <AppNavbar />
      {/* {hasBreadCrumbs ? ( */}
        <Container fluid className="mt-4">
          <AppBreadcrumb hasBreadCrumbs={hasBreadCrumbs} breadCrumbs={breadCrumbs} title={title} />
        </Container>
      {/* ) : null} */}
      {children}
    </div>
  );
};

export default Header;
