import Header from "components/Layout/Header/Header";
import Footer from "components/Layout/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Stack, Image } from "react-bootstrap";
import styles from "./styles.module.scss";
import SearchItem from "./Item";
import LoadingBlueIcon from "assets/images/loading-blue.svg";
import { useEffect, useState } from "react";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { searchRequest } from "networking/search";

const coursesBreadCrumbs = [
  {
    id: 1,
    name: "HeaderTwo.main",
    href: "/",
  },
  {
    id: 2,
    name: "HeaderTwo.news",
    href: "/news",
  },
];

const SearchPage = () => {
  const { t } = useTranslation("search");
  const { language } = useSelector((state: RootState) => state.app);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchKeyword = searchParams.get("keyword") || "";

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);

  useEffect(() => {
    if (searchKeyword) {
      setIsLoading(true);
      searchRequest(searchKeyword)
        .then((res) => {
          console.log({ res });
          setSearchResults(res.data.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [language, searchKeyword]);

  const noSearchResults =
    (searchResults.articles?.length === 0 &&
      searchResults.news?.length === 0 &&
      searchResults.courses?.length === 0 &&
      searchResults.workshops?.length === 0 &&
      searchResults.webinars?.length === 0 &&
      searchResults.events?.length === 0 &&
      searchResults.researchs?.length === 0) ||
    !searchKeyword;

  return (
    <div>
      <Header
        title={`${t("searchResults")}`}
        // hasBreadCrumbs
        breadCrumbs={coursesBreadCrumbs}
      >
        <div className={styles.headerBox} />
      </Header>
      <Stack className={styles.outerContainer}>
        {isLoading ? (
          <div className="py-5 mt-5">
            <Image color="red" src={LoadingBlueIcon} className="ms-3" />
          </div>
        ) : noSearchResults ? (
          <div className={`${styles.noResults} text-secondary bold fs-4`}>
            {t("noSearchResults")}
          </div>
        ) : (
          <>
            <div className={styles.container}>
              {/* Articles*/}
              {searchResults.articles?.length > 0 && (
                <>
                  <p className="text-secondary bold mb-3 fs-4">
                    {t("articles")}
                  </p>
                  {searchResults.articles?.map((item: any) => {
                    const { image, summary, date, id, tags } = item;
                    return (
                      <SearchItem
                        key={id}
                        newsImage={image}
                        summary={summary}
                        date={date}
                        onClick={() => navigate(`/articles/${id}`)}
                        tag={tags}
                      />
                    );
                  })}
                </>
              )}

              {/*News*/}
              {searchResults.news?.length > 0 && (
                <>
                  <p className="text-secondary bold mb-3 fs-4">{t("news")}</p>
                  {searchResults.news?.map((item: any) => {
                    const { image, summary, date, id, tags } = item;
                    return (
                      <SearchItem
                        key={id}
                        newsImage={image}
                        summary={summary}
                        date={date}
                        onClick={() => navigate(`/news/${id}`)}
                        tag={tags}
                      />
                    );
                  })}
                </>
              )}

              {searchResults.courses?.length > 0 && (
                <>
                  <p className="text-secondary bold mb-3 fs-4">
                    {t("courses")}
                  </p>
                  {searchResults.courses?.map((item: any) => {
                    const { image, title, start_date, id, tags } = item;
                    return (
                      <SearchItem
                        key={id}
                        newsImage={image}
                        summary={title}
                        date={start_date}
                        onClick={() => navigate(`/courses/${id}`)}
                        tag={tags}
                      />
                    );
                  })}
                </>
              )}

              {searchResults.workshops?.length > 0 && (
                <>
                  <p className="text-secondary bold mb-3 fs-4">
                    {t("workShops")}
                  </p>
                  {searchResults.workshops?.map((item: any) => {
                    const { image, title, start_date, id, tags } = item;
                    return (
                      <SearchItem
                        key={id}
                        newsImage={image}
                        summary={title}
                        date={start_date}
                        onClick={() => navigate(`/workshops/${id}`)}
                        tag={tags}
                      />
                    );
                  })}
                </>
              )}

              {searchResults.webinars?.length > 0 && (
                <>
                  <p className="text-secondary bold mb-3 fs-4">
                    {t("webinars")}
                  </p>
                  {searchResults.webinars?.map((item: any) => {
                    const { image, title, start_date, id } = item;
                    return (
                      <SearchItem
                        key={id}
                        newsImage={image}
                        summary={title}
                        date={start_date}
                        onClick={() => navigate(`/webinars/${id}`)}
                      />
                    );
                  })}
                </>
              )}

              {searchResults.events?.length > 0 && (
                <>
                  <p className="text-secondary bold mb-3 fs-4">{t("events")}</p>
                  {searchResults.events?.map((item: any) => {
                    const { image, summary, start_date, id, tags } = item;
                    return (
                      <SearchItem
                        key={id}
                        newsImage={image}
                        summary={summary}
                        date={start_date}
                        onClick={() => navigate(`/events/${id}`)}
                        tag={tags}
                      />
                    );
                  })}
                </>
              )}

              {searchResults.researchs?.length > 0 && (
                <>
                  <p className="text-secondary bold mb-3 fs-4">
                    {t("research")}
                  </p>
                  {searchResults.researchs?.map((item: any) => {
                    const { image, title, date, id, tags } = item;
                    return (
                      <SearchItem
                        key={id}
                        newsImage={image}
                        summary={title}
                        date={date}
                        onClick={() => navigate(`/researches/${id}`)}
                        tag={tags}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </>
        )}
      </Stack>
      <Footer />
    </div>
  );
};
export default SearchPage;
