import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import "./AppBreadcrumbstyles.scss";

interface IBreadCrumbProps {
  id: number;
  name: string;
  href: string;
}
interface IBreadCrumbsProps {
  breadCrumbs: IBreadCrumbProps[];
  title?: string;
  hasBreadCrumbs?: boolean;
}

const AppBreadcrumb = ({
  breadCrumbs,
  title,
  hasBreadCrumbs,
}: IBreadCrumbsProps) => {
  const { t } = useTranslation();
  return (
    <>
      {title && (
        <h2 className="fs-42 ff-bold2 text-white z-index-10 mb-2"> {title} </h2>
      )}
      {hasBreadCrumbs && (
        <Breadcrumb className="bread-crumb">
          {breadCrumbs.map((breadCrumb) => (
            <NavLink
              to={breadCrumb.href}
              className={`bread-crumb__item fs-6`}
              key={breadCrumb.id}
            >
              {t(`${breadCrumb.name}`)}
            </NavLink>
          ))}
        </Breadcrumb>
      )}
    </>
  );
};

export default AppBreadcrumb;
