export const localStorageConstants = {
    USER: "user",
    LANGUAGE: 'lang',
    TOKEN: 'token'
}

export const playerConstants = {
    PLAYING:"PLAYING",
    PAUSED:"PAUSED",
    ENDED:"ENDED"
}

export const records: any = {
    WEBINAR: "webinar",
    COURSE: "course",
    WORKSHOP: "workshop"
}


export const paymentConstants = {
    PRICE:"price",
    DISCOUNT:"discount",
    USER_ID: "uid",
    MEMBERSHIP_TYPE: "membership_type"
}