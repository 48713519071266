import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Routing from './routes/Routing';
import { Helmet } from "react-helmet";
import { ToastContainer } from 'react-toastify';


import "./assets/stylesheet/App.scss"
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

function App() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);
  return (
    <div>
      <Helmet>
        <title> {t("safa")} </title>
      </Helmet>
      <ToastContainer />
      <Routing />
    </div>
  );
}

export default App;
