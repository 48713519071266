import { t } from "i18next";
import moment from "moment";
import i18n from '../../i18n';

const timeLang = i18n.language === "ar" ? "LTA" : "LT";

export const DefaultMomentDateAndTimeFormat = (date: string) => {
  return `${moment(date).format(`dddd  l`)} ${moment(date).format(timeLang)}`
}


export const MomentMonthFromTimeFormat = (date: string) => {
  return `${moment(date).format(`dddd LL`)} ${t('from')} ${moment(date).format(timeLang)}`
}

export const DefaultMomentDateFormat = (date: string) => {
  
  return `${moment(date).format(`dddd  D MMMM yyyy`)} `
}

export const DefaultMomentTimeFormat = (time: any) => {
  return `${moment(time, 'HH:mm:ss')}`
}

export const ParseTime = (time: string) =>  {
  let timeInt = parseInt(time);
  let minutes = time.substring(3,5);

  // you could then add or subtract time here as needed
  if(time > '12:00') {
  
  return {
    time:`${timeInt - 12}:${minutes}`,
    amOrpm:`${t("PM")}`
  };
  } else {
    
    return {
      time:`${timeInt}:${minutes}`,
      amOrpm:`${t("AM")}`
    };
  }
}