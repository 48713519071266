import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
// Bootstrap
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";

//Redux
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { logout as logoutAction } from "../../redux/authReducer";
//Components
import Icon from "../Shared/Icon/Icon";
import LangSwitch from "../Shared/LangSwitch/LangSwitch";
//Images
import logo from "../../assets/images/safa-logo.svg";
//SCSS
import "./NavOnestyles.scss";
import { getUnseenNotifications } from "networking/notifications";

export default function NavOne() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get("keyword") || "";
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const { user } = useSelector((state: RootState) => state.auth);
  const [notificationsCount, setNotificationsCount] = useState(0);

  const isLogged = useSelector((state: RootState) => state.auth.isLogged);

  const handleLogout = () => {
    dispatch(logoutAction({}));
    navigate("/");
  };

  const navigateToAgenda = () => {
    navigate("/calendar");
  };

  const navigateToNotifications = () => {
    navigate("/profile/notifications");
  };

  useEffect(() => {
    function getNotificationsCount() {
      getUnseenNotifications().then((res) => {
        setNotificationsCount(res.data.data.count);
      });
    }
    if (isLogged) {
      getNotificationsCount();
      const interval = setInterval(() => getNotificationsCount(), 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  return (
    <div className="nav-one">
      <Navbar
        bg="transparent"
        className="mb-3"
        style={{ zIndex: "20" }}
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <Image src={logo} alt="Safa Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
          >
            <Offcanvas.Header
              closeButton
              className="bg-med-purple bg-lg-transparent"
            >
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand`}
                className="text-white"
              >
                القائمة الرئيسية
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="bg-purple bg-lg-transparent">
              <Nav className="justify-content-end flex-grow-1 pe-3 gap-5">
                <LangSwitch className="text-white" />

                <Form className="nav-one__search">
                  <div className="d-flex border-bottom align-items-baseline">
                    <div
                      className="nav-one__search_icon"
                      onClick={() => {
                        navigate(`/search?keyword=${searchKeyword}`);
                      }}
                    >
                      <Icon
                        iconName="search"
                        hasStroke
                        variant="white"
                        className="pt-1"
                      />
                    </div>

                    <FormControl
                      type="search"
                      placeholder={t("navOne.placeholder")}
                      className="ff-bold2 fs-6"
                      aria-label="Search"
                      onChange={(e) => setSearchKeyword(e.target.value)}
                      value={searchKeyword}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          navigate(`/search?keyword=${searchKeyword}`);
                        }
                      }}
                    />
                  </div>
                </Form>

                {isLogged ? (
                  <>
                    <Nav.Link onClick={navigateToAgenda}>
                      <Icon
                        iconName="calendar"
                        variant="secondary"
                        iconSize={27}
                        className="me-2"
                      />
                      <span className="ff-bold2 fs-8 text-secondary">
                        {t("navOne.agenda")}
                      </span>
                    </Nav.Link>
                    <Nav.Link onClick={navigateToNotifications}>
                      <div className="position-relative">
                        <Icon
                          iconName="notifications"
                          variant="secondary"
                          iconSize={27}
                          // className="me-2"
                        />
                        {notificationsCount > 0 && (
                          <div className="nav-one__badge position-absolute top-0 start-0" />
                        )}
                      </div>
                    </Nav.Link>
                    <div className="position-relative">
                      {user.avatar ? (
                        <img
                          width={50}
                          height={50}
                          src={user.avatar}
                          alt="user-image"
                          className="rounded-circle"
                        />
                      ) : (
                        <Icon
                          iconName="profile"
                          iconSize={50}
                          className="bg-primary rounded-circle"
                        />
                      )}

                      <div className="position-absolute top-0 start-0">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="overflow-hidden"
                            style={{ width: "50px", opacity: "0" }}
                          >
                            Dropdown
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link to="/profile"> {t("navOne.profile")} </Link>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>
                              {t("navOne.logout")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    {/* <Link to="/profile">
                    </Link> */}
                  </>
                ) : (
                  <div className="d-flex align-items-end">
                    <div className="d-flex">
                      <Button
                        onClick={() => navigate("/login")}
                        variant="primary"
                        className="mx-1"
                      >
                        {t("navOne.login")}
                      </Button>

                      <Button
                        onClick={() => navigate("/memberships")}
                        variant="secondary"
                        className="text-white"
                      >
                        {t("navOne.register")}
                      </Button>
                    </div>
                  </div>
                )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}
