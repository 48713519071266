import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

// Bootstrap
import Container from "react-bootstrap/esm/Container";
import Navbar from "react-bootstrap/esm/Navbar";
import Offcanvas from "react-bootstrap/esm/Offcanvas";

import Nav from "react-bootstrap/esm/Nav";

const AppNavbar = () => {
  const { t } = useTranslation();

  const navbarItems = [
    {
      id: 1,
      name: t("home"),
      href: "/",
    },
    {
      id: 2,
      name: t("navbar.aboutUs"),
      href: "/about-us",
    },
    {
      id: 3,
      name: t("navbar.services"),
      href: "/services",
    },
    {
      id: 4,
      name: t("navbar.events"),
      href: "/events",
    },
    {
      id: 5,
      name: t("navbar.courses"),
      href: "/courses",
    },
    {
      id: 6,
      name: t("navbar.webinars"),
      href: "/webinars",
    },
    {
      id: 7,
      name: t("navbar.workShops"),
      href: "/workshops",
    },
    {
      id: 8,
      name: t("navbar.news"),
      href: "/news",
    },
    {
      id: 9,
      name: t("navbar.research"),
      href: "/researches",
    },
    {
      id: 10,
      name: t("navbar.articles"),
      href: "/articles",
    },
    {
      id: 11,
      name: t("navbar.memberShip"),
      href: "/memberships",
    },
    {
      id: 12,
      name: t("navbar.contactUs"),
      href: "/contact-us",
    },
    {
      id: 13,
      name: t("navbar.policies"),
      href: "/policies",
    },
  ];

  return (
    <Container fluid>
      <Navbar
        className="opacity-80 mb-3 bg-white rounded rounded-3 py-4 px-6 z-index-10 ff-medium2 fs-6"
        expand="lg"
      >
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand`}
          aria-labelledby={`offcanvasNavbarLabel-expand`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
              {t("navTwo.title")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-grow-1 pe-3 gap-2">
              {navbarItems.map((nav) => (
                <NavLink
                  to={nav.href}
                  className={({ isActive }) =>
                    isActive ? "active me-3" : "me-3"
                  }
                  key={nav.id}
                >
                  {nav.name}
                </NavLink>
              ))}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </Container>
  );
};

export default AppNavbar;
