export const apiErrorMapper = (error: any , serverError:string) => {
    if(!error.response || !error.response.status) return [];
    if(error.response.status === 400 || error.response.status === 422) {
        const { errors } = error.response.data;
        return errors.map((error: any) => error.value);
    }
    if(error.response.status === 401) {
        const { errors } = error.response.data;
        return errors.map((error: any) => error.value);
    }
    if(error.response.status === 500) {
        return [serverError]
    }  
};
