import i18n from "i18next";
import { localStorageConstants } from "./constants/appConstants";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/ar";

//EN
import translationEN from "./locales/en/en.json";
import profileEN from "./locales/en/profile.json";
import commentsEN from "./locales/en/comments.json";
import webinarsEN from "./locales/en/webinars.json";
import homeEN from "./locales/en/home.json";
import eventsEN from "./locales/en/events.json";
import workshopsEN from "./locales/en/workshops.json";
import coursesEN from "./locales/en/courses.json";
import paymentEN from "./locales/en/payment.json";
import aboutUsEN from "./locales/en/aboutUs.json";
import searchEN from "./locales/en/search.json";
import contactEN from "./locales/en/contactUs.json";
import articlesEN from "./locales/en/articles.json";
import researchesEN from "./locales/en/researches.json";
import messageEN from "./locales/en/message.json";
import servicesEN from "./locales/en/services.json";

//AR
import translationAR from "./locales/ar/ar.json";
import profileAR from "./locales/ar/profile.json";
import commentsAR from "./locales/ar/comments.json";
import webinarsAR from "./locales/ar/webinars.json";
import homeAR from "./locales/ar/home.json";
import eventsAR from "./locales/ar/events.json";
import workshopsAr from "./locales/ar/workshops.json";
import coursesAr from "./locales/ar/courses.json";
import paymentAR from "./locales/ar/payment.json";
import aboutUsAR from "./locales/ar/aboutUs.json";
import searchAR from "./locales/ar/search.json";
import contactAR from "./locales/ar/contactUs.json";
import articlesAR from "./locales/ar/articles.json";
import researchesAr from "./locales/ar/researches.json";
import messageAr from "./locales/ar/message.json";
import servicesAr from "./locales/ar/services.json";

const resources = {
  en: {
    translation: translationEN,
    profile: profileEN,
    workshops: workshopsEN,
    comments: commentsEN,
    webinars: webinarsEN,
    home: homeEN,
    events: eventsEN,
    courses: coursesEN,
    payment: paymentEN,
    aboutUs: aboutUsEN,
    search: searchEN,
    contactUs: contactEN,
    articles: articlesEN,
    researches: researchesEN,
    message: messageEN,
    services: servicesEN,
  },

  ar: {
    translation: translationAR,
    profile: profileAR,
    workshops: workshopsAr,
    comments: commentsAR,
    webinars: webinarsAR,
    home: homeAR,
    events: eventsAR,
    courses: coursesAr,
    payment: paymentAR,
    aboutUs: aboutUsAR,
    search: searchAR,
    contactUs: contactAR,
    articles: articlesAR,
    researches: researchesAr,
    message: messageAr,
    services: servicesAr,
  },
};

const setDocumentLang = (lng: any) => {
  const direction = lng === "ar" ? "rtl" : "ltr";
  const htmlTag = document.querySelector("html") as HTMLElement;

  // assign multiple attributes at once to an <html> tag
  Object.assign(htmlTag, { lang: lng, id: lng, dir: direction });
};

i18n.on("languageChanged", (lng) => {
  setDocumentLang(lng);
  moment.locale(lng);
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem(localStorageConstants.LANGUAGE) || "ar",

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
