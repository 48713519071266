import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import appReducer from "./appReducer";
import profileupdateReducer from "./profileupdateReducer";
import paymentReducer from "./paymentReducer";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    profileUpdate: profileupdateReducer,
    payment: paymentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
