export function home(): string {
  return "/";
}

export function webinars(): string {
  return "/webinars";
}

export function webinar(): string {
  return "/webinars/:id";
}

export function webinarWatch(): string {
  return "/webinars/:id/webinar-watch";
}

export function login(): string {
  return "/login";
}

export function signup(): string {
  return "/signup";
}

export const forgotPassword = "/password/forgot";
export const resetPassword = "/forget-password/:code";

export function memberships(): string {
  return "/memberships";
}

export function profile(): string {
  return "/profile";
}

export function workshops(): string {
  return "/workshops";
}

export function workshop(): string {
  return "/workshops/:id";
}

export function calendar(): string {
  return "/calendar";
}

export function courses(): string {
  return "/courses";
}

export function course(): string {
  return "/courses/:id";
}

export function coursePayment(): string {
  return "/courses/:id/payment";
}

export function workshopPayment(): string {
  return "/workshops/:id/payment";
}

export function signupPayment(): string {
  return "/signup-payment";
}

export function paymentRedirection(): string {
  return "/payment-redirection/:id";
}

const aboutUs = "/about-us";
const policies = "/policies";
const courseWatch = "/courses/:id/course-watch";
const workshopWatch = "/workshops/:id/workshop-watch";
const news = "/news";
const singleNews = "/news/:id";
const search = "/search";
const contact = "contact-us";
const events = "/events";
const event = "/events/:id";
const eventWatch = "/events/:id/event-watch";
const articles = "/articles";
const article = "/articles/:id";
const researches = "/researches";
const research = "/researches/:id";
const researchPayment = "/researches/:id/payment";
const servicesPage = "/services";

export const paths = {
  courseWatch,
  workshopWatch,
  aboutUs,
  news,
  singleNews,
  search,
  contact,
  events,
  event,
  eventWatch,
  articles,
  article,
  researches,
  research,
  researchPayment,
  servicesPage,
  forgotPassword,
  resetPassword,
  policies
};
