import { createSlice } from "@reduxjs/toolkit";
import { paymentConstants } from "../constants/appConstants";

export interface paymentInterface {
  price: number;
  discount: number;
  userId: number;
  memebership_type: number;
}

const initialState: paymentInterface = {
  price: parseInt(localStorage.getItem(paymentConstants.PRICE) || "1"),
  discount: parseInt(localStorage.getItem(paymentConstants.DISCOUNT) || "1"),
  userId: parseInt(localStorage.getItem(paymentConstants.USER_ID) || "0"),
  memebership_type: parseInt(
    localStorage.getItem(paymentConstants.MEMBERSHIP_TYPE) || "2"
  ),
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    initPaymentData: (state, action) => {
      state.price = action.payload.price;
      state.discount = action.payload.discount;
      state.userId = action.payload.userId;

      if (action.payload.memebership_type) {
        state.memebership_type = action.payload.memebership_type;
        localStorage.setItem(
          paymentConstants.MEMBERSHIP_TYPE,
          state.memebership_type.toString()
        );
      }

      localStorage.setItem(paymentConstants.PRICE, state.price.toString());
      localStorage.setItem(
        paymentConstants.DISCOUNT,
        state.discount.toString()
      );
      localStorage.setItem(paymentConstants.USER_ID, state.userId.toString());
    },
    clearPaymentData: (state) => {
      state.price = 0;
      state.discount = 0;
      state.userId = 0;
      state.memebership_type = 0;

      localStorage.removeItem(paymentConstants.PRICE);
      localStorage.removeItem(paymentConstants.DISCOUNT);
      localStorage.removeItem(paymentConstants.MEMBERSHIP_TYPE);
      localStorage.removeItem(paymentConstants.USER_ID);
    },
  },
});

export const { initPaymentData, clearPaymentData } = paymentSlice.actions;
export default paymentSlice.reducer;
