import Header from "components/Layout/Header/Header";
import { Col, Container, Row, Stack, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import FormikErrorMessage from "components/Shared/FormikErrorMessage/FormikErrorMessage";
import Validation from "core/Helpers/validation";
import sendQuestionImgPath from "assets/images/send-question.png";
import { contact } from "networking/contact";
import { useState } from "react";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

const coursesBreadCrumbs = [
  {
    id: 1,
    name: "HeaderTwo.main",
    href: "/",
  },
  {
    id: 2,
    name: "HeaderTwo.contactUs",
    href: "/contact-us",
  },
];

const ContactPage = () => {
  const { t, i18n } = useTranslation("contactUs");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ) => {
    const formData = new FormData();
    formData.append("name",  `${values.full_name} ${values.family_name}`);
    formData.append("email", values.email);
    formData.append("message", values.message);

    setIsLoading(true);
    contact(formData)
      .then(() => {
        setIsLoading(false);
        toast.success(`${t(`theMessageHasBeenSaved`)}`);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <Header
      title={`${t("contactUs")}`}
      hasBreadCrumbs
      breadCrumbs={coursesBreadCrumbs}
    >
      <Container fluid>
        <div
          className={`z-3 position-relative
                      ${
                        i18n.language === "ar"
                          ? styles.containerPaddingAr
                          : styles.containerPaddingEn
                      }
                      ${styles.container}
                    `}
        >
          <Row>
            <Col xs={12} md={6} className="d-flex align-items-center">
              {/* Todo : image to be replaced with design image as it is not exportable */}
              <Image
                src={sendQuestionImgPath}
                className={`${styles.contact_us_img}  ${
                  i18n.language === "ar" ? "" : styles.contact_us_img_en
                }`}
              />
            </Col>
            <Col xs={12} md={6}>
              <h1>{`${t("sendMessage")}`}</h1>
              <p>{`${t("youCanContact")}`}</p>
              <Formik
                initialValues={{
                  email: "",
                  message: "",
                  full_name: "",
                  family_name: "",
                }}
                validationSchema={Validation().contactUsValidationsSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, errors, isValid, dirty }) => (
                  <Form>
                    {/* /////////// full name ///////// */}
                    <label
                      htmlFor="full_name"
                      className={`bg-white py-1 px-2 ms-2 text-secondary`}
                    >
                      {t("fullName")}
                    </label>
                    <Field
                      type="name"
                      name="full_name"
                      className={`d-block mb-3 w-100 p-3 rounded-2  border 
                     
                      ${
                        errors.full_name ? "border-danger" : "border-gray-500"
                      }`}
                    />
                    <FormikErrorMessage name="full_name" />

                    {/* /////////// family name ///////// */}
                    <label
                      htmlFor="family_name"
                      className={`bg-white py-1 px-2 ms-2 text-secondary`}
                    >
                      {t("familyName")}
                    </label>
                    <Field
                      type="name"
                      name="family_name"
                      className={`d-block mb-3 w-100 p-3  rounded-2  border 
                     
                      ${
                        errors.family_name ? "border-danger" : "border-gray-500"
                      }`}
                    />
                    <FormikErrorMessage name="family_name" />

                    {/* /////////// email ///////// */}

                    <label
                      htmlFor="email"
                      className={`bg-white py-1 px-2 ms-2 text-secondary`}
                    >
                      {t("email")}
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className={`d-block mb-3 w-100 p-3  rounded-2  border 
                      
                      ${errors.email ? "border-danger" : "border-gray-500"}`}
                    />
                    <FormikErrorMessage name="email" />

                    {/* /////////// message ///////// */}

                    <label
                      htmlFor="message"
                      className={`bg-white py-1 px-2 ms-2 text-secondary `}
                    >
                      {t("message")}
                    </label>
                    <Field
                      as="textarea"
                      name="message"
                      className={`d-block  mb-3 w-100 p-3 rounded-2  border mt-n3 ${
                        styles.messageInput
                      } ${
                        errors.message ? "border-danger" : "border-gray-500"
                      }`}
                    />
                    <FormikErrorMessage name="message" />

                    <Stack className="d-flex  align-items-center w-100">
                      <button
                        type="submit"
                        className={`btn text-white bg-primary w-xs-50 w-md-25 p-3 ff-bold3 fs-6 mt-3 `}
                        disabled={isLoading ? true : false}
                      >
                        {t("saveTheEdits")}
                      </button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>{" "}
        </div>
      </Container>
    </Header>
  );
};
export default ContactPage;
