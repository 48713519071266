import { createSlice } from "@reduxjs/toolkit";
import { localStorageConstants } from "../constants/appConstants";

export interface appInitialInterface {
  language: string;
}

const initialState: appInitialInterface = {
  language: localStorage.getItem(localStorageConstants.LANGUAGE) || "ar",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload.language;
      localStorage.setItem(localStorageConstants.LANGUAGE, state.language);
    },
  },
});

export const { changeLanguage } = appSlice.actions;
export default appSlice.reducer;
