import { useTranslation } from "react-i18next";
import { useFormikContext, getIn } from "formik";


interface ITranslateError {
  key: string,
  values: any
}

const translateError = (t:any, { key, values }: ITranslateError) => {
  const translatedValues = Object.keys(values).reduce((accumulator:any, valueKey) => {
    if (typeof values[valueKey] === "function") {
      accumulator[valueKey] = values[valueKey](t);
    } else {
      accumulator[valueKey] = values[valueKey];
    }

    return accumulator;
  }, {});

  return t(key, translatedValues);
};

interface IFormikErrorMessageProps {
  name:string
  className?: string,
}

const FormikErrorMessage = ({ name, className }: IFormikErrorMessageProps) => {
  const { errors, touched } = useFormikContext();
  const [fieldError, fieldTouched] = [getIn(errors, name), getIn(touched, name)];
  const { t } = useTranslation();

  if (!fieldError || !fieldTouched) {
    return null;
  }

  let errorMessage = fieldError;
  if (fieldError.values) {
    errorMessage = translateError(t, fieldError);
  } else if (fieldError.key) {
    errorMessage = t(fieldError.key);
  }

  return (
    <div className={`text-danger ff-medium2 ${className}`}>
      {errorMessage}
    </div>
  );
};

export default FormikErrorMessage;