// Bootstrap
import { Container, Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";

// LOGO
import logo from "../../../assets/images/safa-dark-logo.svg";

// SCSS
import styles from "./styles.module.scss";

// Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faGoogle,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { subscribe } from "networking/home";
import { toast } from "react-toastify";
import { useState } from "react";
import { apiErrorMapper } from "utils/errorHandle";

export default function Footer() {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navbarItems = [
    {
      id: 1,
      name: t("home"),
      href: "/",
    },
    {
      id: 2,
      name: t("navbar.aboutUs"),
      href: "/about-us",
    },
    {
      id: 3,
      name: t("navbar.services"),
      href: "/services",
    },
    {
      id: 4,
      name: t("navbar.events"),
      href: "/events",
    },
    {
      id: 5,
      name: t("navbar.courses"),
      href: "/courses",
    },
    {
      id: 6,
      name: t("navbar.webinars"),
      href: "/webinars",
    },
    {
      id: 7,
      name: t("navbar.workShops"),
      href: "/workshops",
    },
    {
      id: 8,
      name: t("navbar.news"),
      href: "/news",
    },
    {
      id: 9,
      name: t("navbar.research"),
      href: "/researches",
    },
    {
      id: 10,
      name: t("navbar.teamWork"),
      href: "/team-work",
    },
    {
      id: 11,
      name: t("navbar.memberShip"),
      href: "/memberships",
    },
    {
      id: 12,
      name: t("navbar.contactUs"),
      href: "/contact-us",
    },
  ];

  const handleSubscribe = () => {
    if (!email) {
      toast.error(t(`footer.enterValidEmail`));
    }
    setIsLoading(true);
    subscribe({
      email: email,
    })
      .then((response) => {
        toast.success(`${t(`footer.subscribedSuccessfully`)}`);
        setEmail("");
        setIsLoading(false);
      })
      .catch((err) => {
        const messages = apiErrorMapper(err, t(`serverError`));
        messages.forEach((m: string) => toast.error(m));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.footer}>
      <Container className="px-0 my-5">
        <Row>
          <Col xs={12} md={4} className={`${styles.logo} mb-4 `}>
            <Link to="/">
              <img src={logo} alt="Safa logo" />
            </Link>
            <p className="mb-1 mt-4">Safa@gmail.com</p>
            <p>+757 699-4478</p>
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <h4 className="mb-4"> {t("footer.links")} </h4>
            <div className="d-flex justify-content-center justify-content-md-start">
              <Nav
                defaultActiveKey="/home"
                className="flex-column me-120 gap-3"
              >
                {navbarItems.slice(0, 6).map((item) => (
                  <Link to={item.href} key={item.id} className="ps-0">
                    {item.name}
                  </Link>
                ))}
              </Nav>

              <Nav defaultActiveKey="/home" className="flex-column gap-3">
                {navbarItems.slice(6, 12).map((item) => (
                  <Link to={item.href} key={item.id}>
                    {item.name}
                  </Link>
                ))}
              </Nav>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <h4 className="mb-4">{t("footer.follow")}</h4>
            <div className={styles.icons}>
              <Link className="me-3" to="/">
                <FontAwesomeIcon icon={faGoogle} />
              </Link>
              <Link className="me-3" to="/">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </Link>
              <Link className="me-3" to="/">
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
              <Link className="me-3" to="/">
                <FontAwesomeIcon icon={faFacebookF} />
              </Link>
            </div>
            <h4 className="my-4"> {t("footer.news")} </h4>
            <InputGroup className={styles.form} style={{ height: "58px" }}>
              <Form.Control
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className="rounded p-3 bold"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <Button
                onClick={handleSubscribe}
                className={`${styles.btn} rounded`}
                id="button-addon2"
                disabled={isLoading}
              >
                {t("footer.subscribe")}{" "}
              </Button>
            </InputGroup>
          </Col>
        </Row>
      </Container>
      <p className={`${styles.copy} text-center pb-5 `}> {t("footer.copy")} </p>
    </div>
  );
}
