import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  webinars,
  login,
  signup,
  memberships,
  profile,
  workshops,
  webinar,
  home,
  calendar,
  webinarWatch,
  courses,
  workshop,
  course,
  coursePayment,
  workshopPayment,
  signupPayment,
  paymentRedirection,
  paths,
} from "./Paths";

import { Spinner } from "react-bootstrap";
import PaymentMembership from "../pages/Memberships/Payment";
import SearchPage from "pages/search";
import ContactPage from "../pages/Contact";

// Routes
const Home = React.lazy(() => import("../pages/Home"));
const Webinars = React.lazy(() => import("../pages/Webinars"));
const Login = React.lazy(() => import("../pages/Login"));
const Signup = React.lazy(() => import("../pages/Signup"));
const ForgotPassword = React.lazy(() => import("../pages/forgot-password"));
const ResetPassword = React.lazy(() => import("../pages/reset-password"));
const Memberships = React.lazy(() => import("../pages/Memberships"));
const Profile = React.lazy(() => import("../pages/Profile"));
const Webinar = React.lazy(() => import("../pages/Webinars/Webinar"));
const Calendar = React.lazy(() => import("../pages/Calendar"));
const WatchLessons = React.lazy(() => import("../pages/WatchLessons"));
const CourseWatchLessons = React.lazy(
  () => import("../pages/Courses/course-watch-lessons")
);
const WorkshopWatchLessons = React.lazy(
  () => import("../pages/Workshops/workshop-watch-lessons")
);
const ProfileMembership = React.lazy(
  () => import("../pages/Profile/Screens/membership")
);
const ProfileTransactions = React.lazy(
  () => import("../pages/Profile/Screens/ProfileTransactions")
);
const ProfileFavorites = React.lazy(
  () => import("../pages/Profile/Screens/Favorite/ProfileFavorites")
);
const ProfileUpdate = React.lazy(
  () => import("../pages/Profile/Screens/EditProfile")
);
const ProfileAddArticle = React.lazy(
  () => import("../pages/Profile/Screens/addArticle")
);
const ProfileMyArticles = React.lazy(
  () => import("../pages/Profile/Screens/myArticles")
);
const ProfileMessages = React.lazy(
  () => import("../pages/Profile/Screens/messages")
);
const NotificationsPage = React.lazy(
  () => import("../pages/Profile/Screens/notifications")
);
const ProfileMembershipCard = React.lazy(
  () => import("../pages/Profile/Screens/membership-card")
);
const ProfileCertificates = React.lazy(
  () => import("../pages/Profile/Screens/certificates")
);

const Workshops = React.lazy(() => import("../pages/Workshops"));
const Workshop = React.lazy(() => import("../pages/Workshops/Workshop"));
const Courses = React.lazy(() => import("../pages/Courses"));
const Course = React.lazy(() => import("../pages/Courses/Course"));
const AboutUs = React.lazy(() => import("../pages/About"));
const PoliciesPage = React.lazy(() => import("../pages/policies"));
const News = React.lazy(() => import("../pages/news"));
const SingleNews = React.lazy(() => import("../pages/singleNews"));
const ProfileHistory = React.lazy(
  () => import("../pages/Profile/Screens/ProfileHistory")
);
const PaymentCourse = React.lazy(() => import("../pages/Courses/Payment"));
const PaymentWorkshop = React.lazy(() => import("../pages/Workshops/Payment"));
const SignupPayment = React.lazy(() => import("../pages/Signup/SignupPayment"));
const PaymentRedirect = React.lazy(() => import("../pages/PaymentRedirection"));
const Events = React.lazy(() => import("../pages/events-page/events-list"));
const Event = React.lazy(() => import("../pages/events-page/event-details"));
const EventWatchLessons = React.lazy(
  () => import("../pages/events-page/event-watch")
);
const ConTactUs = React.lazy(() => import("../pages/Contact"));
const Articles = React.lazy(() => import("../pages/articles-pages/articles"));
const ArticleDetails = React.lazy(
  () => import("../pages/articles-pages/article-details")
);
const Researches = React.lazy(
  () => import("../pages/researches-pages/researches")
);
const ResearchesDetails = React.lazy(
  () => import("../pages/researches-pages/research-details")
);
const PaymentResearch = React.lazy(
  () => import("../pages/researches-pages/research-payment")
);
const ServicesPage = React.lazy(() => import("../pages/Services-page"));
const withSuspense = (WrappedComponent: React.ComponentType) => {
  return (
    <Suspense fallback={<Spinner animation="border" variant="primary" />}>
      <WrappedComponent />
    </Suspense>
  );
};

export const withSuspenseComponents = (element: JSX.Element) => {
  const newComponent = () => withSuspense(element.props.component);

  return { ...element, props: { ...element.props, component: newComponent } };
};

export default function Routing() {
  return (
    <Suspense fallback={<Spinner animation="border" variant="primary" />}>
      <BrowserRouter>
        <Routes>
          <Route
            key="home"
            path={home()}
            element={withSuspenseComponents(<Home />)}
          />

          <Route
            key="webinars"
            path={webinars()}
            element={withSuspenseComponents(<Webinars />)}
          />

          <Route
            key="webinar"
            path={webinar()}
            element={withSuspenseComponents(<Webinar />)}
          />

          <Route
            key="webinarWatch"
            path={webinarWatch()}
            element={withSuspenseComponents(<WatchLessons />)}
          />

          <Route
            key="login"
            path={login()}
            element={withSuspenseComponents(<Login />)}
          />
          <Route
            key="signup"
            path={signup()}
            element={withSuspenseComponents(<Signup />)}
          />
          <Route
            key="forgot-pass"
            path={paths.forgotPassword}
            element={withSuspenseComponents(<ForgotPassword />)}
          />
          <Route
            key="reset-pass"
            path={paths.resetPassword}
            element={withSuspenseComponents(<ResetPassword />)}
          />
          <Route
            key="memberships"
            path={memberships()}
            element={withSuspenseComponents(<Memberships />)}
          />

          <Route
            key="profile"
            path={profile()}
            element={withSuspenseComponents(<Profile />)}
          >
            <Route
              path="/profile"
              element={withSuspenseComponents(<ProfileUpdate />)}
            />
            <Route
              path="notifications"
              element={withSuspenseComponents(<NotificationsPage />)}
            />
            <Route
              path="membership"
              element={withSuspenseComponents(<ProfileMembership />)}
            />
            <Route
              path="history"
              element={withSuspenseComponents(<ProfileHistory />)}
            />
            <Route
              path="transactions"
              element={withSuspenseComponents(<ProfileTransactions />)}
            />
            <Route
              path="favorites"
              element={withSuspenseComponents(<ProfileFavorites />)}
            />
            <Route
              path="add-article"
              element={withSuspenseComponents(<ProfileAddArticle />)}
            />
            <Route
              path="my-articles"
              element={withSuspenseComponents(<ProfileMyArticles />)}
            />
            <Route
              path="/profile/my-article/:id/edit"
              element={withSuspenseComponents(<ProfileAddArticle />)}
            />
            <Route
              path="/profile/messages"
              element={withSuspenseComponents(<ProfileMessages />)}
            />
            <Route
              path="/profile/membership-card"
              element={withSuspenseComponents(<ProfileMembershipCard />)}
            />

            <Route
              path="/profile/certificates"
              element={withSuspenseComponents(<ProfileCertificates />)}
            />
          </Route>

          <Route
            key="workshops"
            path={workshops()}
            element={withSuspenseComponents(<Workshops />)}
          />

          <Route
            key="workshop"
            path={workshop()}
            element={withSuspenseComponents(<Workshop />)}
          />

          <Route
            key="workshopWatch"
            path={paths.workshopWatch}
            element={withSuspenseComponents(<WorkshopWatchLessons />)}
          />

          <Route
            key="calendar"
            path={calendar()}
            element={withSuspenseComponents(<Calendar />)}
          />

          <Route
            key="courses"
            path={courses()}
            element={withSuspenseComponents(<Courses />)}
          />

          <Route
            key="course"
            path={course()}
            element={withSuspenseComponents(<Course />)}
          />

          <Route
            key="courseWatch"
            path={paths.courseWatch}
            element={withSuspenseComponents(<CourseWatchLessons />)}
          />

          <Route
            key="signupPayment"
            path={signupPayment()}
            element={withSuspenseComponents(<SignupPayment />)}
          />

          <Route
            key="coursePayment"
            path={coursePayment()}
            element={withSuspenseComponents(<PaymentCourse />)}
          />

          <Route
            key="workshopPayment"
            path={workshopPayment()}
            element={withSuspenseComponents(<PaymentWorkshop />)}
          />

          <Route
            key="paymentRedirection"
            path={paymentRedirection()}
            element={withSuspenseComponents(<PaymentRedirect />)}
          />
          <Route
            key="about"
            path={paths.aboutUs}
            element={withSuspenseComponents(<AboutUs />)}
          />
          <Route
            key="policies"
            path={paths.policies}
            element={withSuspenseComponents(<PoliciesPage />)}
          />
          <Route
            key="news"
            path={paths.news}
            element={withSuspenseComponents(<News />)}
          />
          <Route
            key="singleNews"
            path={paths.singleNews}
            element={withSuspenseComponents(<SingleNews />)}
          />
          <Route
            key="search"
            path={paths.search}
            element={withSuspenseComponents(<SearchPage />)}
          />
          <Route
            key="contact"
            path={paths.contact}
            element={withSuspenseComponents(<ContactPage />)}
          />
          {/* events pages */}
          <Route
            key="events"
            path={paths.events}
            element={withSuspenseComponents(<Events />)}
          />
          <Route
            key="event"
            path={paths.event}
            element={withSuspenseComponents(<Event />)}
          />
          <Route
            key="eventWatch"
            path={paths.eventWatch}
            element={withSuspenseComponents(<EventWatchLessons />)}
          />
          {/* articles pages */}
          <Route
            key="articles"
            path={paths.articles}
            element={withSuspenseComponents(<Articles />)}
          />
          <Route
            key="article"
            path={paths.article}
            element={withSuspenseComponents(<ArticleDetails />)}
          />
          <Route
            key="researches"
            path={paths.researches}
            element={withSuspenseComponents(<Researches />)}
          />
          <Route
            key="research"
            path={paths.research}
            element={withSuspenseComponents(<ResearchesDetails />)}
          />
          <Route
            key="researchPayment"
            path={paths.researchPayment}
            element={withSuspenseComponents(<PaymentResearch />)}
          />
          <Route
            key="servicesPage"
            path={paths.servicesPage}
            element={withSuspenseComponents(<ServicesPage />)}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
