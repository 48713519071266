import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import {RootState} from '../../../redux/store'
import { useDispatch , useSelector } from 'react-redux';
import {changeLanguage as languageAction } from '../../../redux/appReducer'

interface ILangSwitchProps {
  className?: string
}

const LangSwitch = ({ className = "" }: ILangSwitchProps) => {

  const dispatch = useDispatch()
  const { i18n } = useTranslation("translation");
  const {language} = useSelector((state:RootState) => state.app)

  const changeLanguage = () => {
    const newLang = language === "ar" ? "en" : "ar"
    i18n.changeLanguage(newLang);
    dispatch(languageAction({language:newLang}))
  }


  return (
    <Button variant='link' className={`p-0 ff-bold2 fs-6 ${className}`}
    onClick={changeLanguage}>
      {i18n.language === "ar" ? "EN" : "AR"}
    </Button>
  )
}

export default LangSwitch;