import { createSlice } from "@reduxjs/toolkit";

export interface appInitialInterface {
  uploadedImage: any;
}

const initialState: appInitialInterface = {
  uploadedImage: null,
};

const uploaderSlice = createSlice({
  name: "profileUpdate",
  initialState,
  reducers: {
    uploadImage: (state, action) => {
      state.uploadedImage = action.payload;
    },
  },
});

export const { uploadImage } = uploaderSlice.actions;
export default uploaderSlice.reducer;
