import { createSlice } from '@reduxjs/toolkit';
// import type { PayloadAction } from "@reduxjs/toolkit";
import { profileUser } from '../interfaces/authInterface';
import { localStorageConstants } from '../constants/appConstants';

export interface authInitialInterface {
  user: profileUser;
  isLogged: boolean;
  token: string | null;
}

const initialState: authInitialInterface = {
  user: localStorage.getItem(localStorageConstants.USER)
    ? JSON.parse(`${localStorage.getItem(localStorageConstants.USER)}`)
    : <profileUser>{},
  isLogged: localStorage.getItem(localStorageConstants.TOKEN) ? true : false,
  token: localStorage.getItem(localStorageConstants.TOKEN),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user.user;
      state.isLogged = true;
      state.token = action.payload.user.user.token;

      localStorage.setItem(
        localStorageConstants.USER,
        JSON.stringify(state.user)
      );
      localStorage.setItem(localStorageConstants.TOKEN, state.user.token);
    },
    logout: (state, action) => {
      state.user = <profileUser>{};
      state.isLogged = false;
      state.token = null;

      localStorage.removeItem(localStorageConstants.USER);
      localStorage.removeItem(localStorageConstants.TOKEN);
    },
    updateProfile: (state, action) => {
      state.user = action.payload.user.user;

      localStorage.setItem(
        localStorageConstants.USER,
        JSON.stringify(state.user)
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, updateProfile } = authSlice.actions;

export default authSlice.reducer;
