import Icons from "./IconsSvg.svg";
interface IIconProps {
  iconName: string,
  iconSize?: number,
  className?: string,
  style?: object,
  variant?: "primary" | "secondary" | "warning" | "white" | "danger" | "success" | "white" | "black" | "dark" | "blue" | "gray-400" | "orange",
  height?: number,
  hasStroke?: boolean,
  fillOpacity?: number,
  // for .md file only
  isForUiDocumentation?: boolean,
}


const Icon = ({
  iconName,
  iconSize = 20,
  className =  "",
  style,
  variant,
  height,
  hasStroke = false,
  fillOpacity,
  isForUiDocumentation = false
}: IIconProps) => {

  const ICONCOLOR: any = {
    primary: "#5260ab",
    secondary: "#51bfad",
    warning: "#ffd36f",
    danger: "#e30613",
    success: "#36b600",
    white: "#fff",
    black: "#000",
    dark: "#333",
    blue: "#303a6c",
    "gray-400": "#ddd",
    orange: "#f69c5d"
  }

  // It will only be used in the Icon.md to show Icon in ui documentation
  const xlinkHrefForUiDocumentation = `/src/components/Shared/Icon/IconsSvg.svg#${iconName}`;

  return (
    <svg
      className={`icon icon-${iconName} ${className}`}
      width={iconSize}
      height={height ? height : iconSize}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      fill={hasStroke ? "none" : ICONCOLOR[variant ?? "white"]}
      stroke={hasStroke ? ICONCOLOR[variant ?? "white"]: "none"}
      fillOpacity={fillOpacity}
    >
      <use xlinkHref={isForUiDocumentation ? xlinkHrefForUiDocumentation : `${Icons}#${iconName}`} />

    </svg>
  )
}

export default Icon;