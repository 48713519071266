import * as Yup from "yup";

export default function Validation() {
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .test("regex", { key: "login.emailMatches" }, (val: any) => {
        let regExp = new RegExp("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}");
        return val ? regExp.test(val) : true;
      })
      .required({ key: "login.emailRequired" }),

    password: Yup.string()
      .min(8, { key: "login.passwordLength" })
      .required({ key: "login.passwordRequired" })
      .test("regex", { key: "login.passwordMatches" }, (val: any) => {
        let regExp = new RegExp("^(?=.*[0-9])");
        return val ? regExp.test(val) : true;
      }),
  });

  const forgotPassSchema = Yup.object().shape({
    email: Yup.string()
      .test("regex", { key: "login.emailMatches" }, (val: any) => {
        let regExp = new RegExp("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}");
        return val ? regExp.test(val) : true;
      })
      .required({ key: "login.emailRequired" }),
  });

  const resetPassSchema = Yup.object().shape({
    password: Yup.string()
      .required({ key: "signup.required" })
      .min(8, { key: "signup.passwordLength" })
      .test("regex", { key: "signup.passwordFormat" }, (val: any) => {
        let regExp = new RegExp(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
        );
        return val ? regExp.test(val) : true;
      }),

    confirm_password: Yup.string()
      .required({ key: "signup.required" })
      .oneOf([Yup.ref("password"), null], {
        key: "signup.confirmPasswordMatch",
      }),
  });

  // SigUp validation
  const RegisterSchema = (isStudentMembership: boolean) => {
    return Yup.object().shape({
      name_ar: Yup.string()
        .test("regex", { key: "signup.matches" }, (val: any) => {
          let regExp = new RegExp("^[\u0621-\u064A\u0660-\u0669 ]+$");
          return val ? regExp.test(val) : true;
        })
        .required({ key: "signup.required" })
        .max(50, { key: "signup.matches" }),

      name_en: Yup.string()
        .test("regex", { key: "signup.matches" }, (val: any) => {
          let regExp = new RegExp("^[a-zA-Z0-9$@$!%*?&#^-_. +]+$");
          return val ? regExp.test(val) : true;
        })
        .required({ key: "signup.required" })
        .max(50, { key: "signup.matches" }),

      nationality_id: Yup.string().required({ key: "signup.required" }),

      id_number: Yup.string()
        .required({ key: "signup.required" })
        .test("regex", { key: "signup.matches" }, (val: any) => {
          let regExp = new RegExp("^(\\d+$)");
          return val ? regExp.test(val) : true;
        })
        .min(14, {
          key: "signup.minOrMixLength",
          values: {
            fieldName: (translate: any) => translate("signup.idLabel"),
            number: 14,
          },
        })
        .max(14, {
          key: "signup.minOrMixLength",
          values: {
            fieldName: (translate: any) => translate("signup.idLabel"),
            number: 14,
          },
        }),

      phone: Yup.string()
        .required({ key: "signup.required" })
        .test("regex", { key: "signup.matches" }, (val: any) => {
          let regExp = new RegExp(
            "^[\\+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$"
          );
          return val ? regExp.test(val) : true;
        })
        .max(11, { key: "signup.phoneLength" })
        .min(11, { key: "signup.phoneLength" }),
        
      ...(isStudentMembership
        ? {
            email: Yup.string()
              .test("regex", { key: "signup.validEmail" }, (val: any) => {
                let regExp = new RegExp("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}");
                return val ? regExp.test(val) : true;
              })
              .test("eduEmail", { key: "signup.eduEmail" }, (value: any) => {
                console.log({value});
                return value?.includes(".edu");
              })
              .max(50, { key: "signup.emailLength" })
              .required({ key: "signup.required" }),
          }
        : {
            email: Yup.string()
              .test("regex", { key: "signup.validEmail" }, (val: any) => {
                let regExp = new RegExp("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}");
                return val ? regExp.test(val) : true;
              })
              .max(50, { key: "signup.emailLength" })
              .required({ key: "signup.required" }),
          }),

      job: Yup.string().required({ key: "signup.required" }),
      education_id: Yup.string().required({ key: "signup.required" }),

      sector_id: Yup.string().required({ key: "signup.required" }),

      admission: Yup.boolean().oneOf([true], { key: "signup.checkboxError" }),

      password: Yup.string()
        .required({ key: "signup.required" })
        .min(8, { key: "signup.passwordLength" })
        .test("regex", { key: "signup.passwordFormat" }, (val: any) => {
          let regExp = new RegExp(
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          );
          return val ? regExp.test(val) : true;
        }),

      confirm_password: Yup.string()
        .required({ key: "signup.required" })
        .oneOf([Yup.ref("password"), null], {
          key: "signup.confirmPasswordMatch",
        }),

      address: Yup.string()
        .required({ key: "signup.required" })
        .max(100, { key: "signup.addressLength" }),

      membership_id: Yup.string().required({ key: "signup.required" }),
      employer: Yup.string().required({ key: "signup.required" }),
    });
  };

  const updateProfileSchema = Yup.object().shape({
    name_ar: Yup.string()
      .test("regex", { key: "signup.matches" }, (val: any) => {
        let regExp = new RegExp("^[\u0621-\u064A\u0660-\u0669 ]+$");
        return val ? regExp.test(val) : true;
      })
      .required({ key: "signup.required" })
      .max(50, { key: "signup.matches" }),

    name_en: Yup.string()
      .test("regex", { key: "signup.matches" }, (val: any) => {
        let regExp = new RegExp("^[a-zA-Z0-9$@$!%*?&#^-_. +]+$");
        return val ? regExp.test(val) : true;
      })
      .required({ key: "signup.required" })
      .max(50, { key: "signup.matches" }),

    nationality_id: Yup.string().required({ key: "signup.required" }),
    id_number: Yup.string()
      .required({ key: "signup.required" })
      .test("regex", { key: "signup.matches" }, (val: any) => {
        let regExp = new RegExp("^(\\d+$)");
        return val ? regExp.test(val) : true;
      })
      .min(14, {
        key: "signup.minOrMixLength",
        values: {
          fieldName: (translate: any) => translate("signup.idLabel"),
          number: 14,
        },
      })
      .max(14, {
        key: "signup.minOrMixLength",
        values: {
          fieldName: (translate: any) => translate("signup.idLabel"),
          number: 14,
        },
      }),
    phone: Yup.string()
      .required({ key: "signup.required" })
      .test("regex", { key: "signup.matches" }, (val: any) => {
        let regExp = new RegExp(
          "^[\\+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$"
        );
        return val ? regExp.test(val) : true;
      })
      .max(11, { key: "signup.phoneLength" })
      .min(11, { key: "signup.phoneLength" }),

    email: Yup.string()
      .test("regex", { key: "login.emailMatches" }, (val: any) => {
        let regExp = new RegExp("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}");
        return val ? regExp.test(val) : true;
      })
      .required({ key: "login.emailRequired" }),

    address: Yup.string()
      .required({ key: "signup.required" })
      .max(100, { key: "signup.addressLength" }),

    job: Yup.string().required({ key: "signup.required" }),
    education_id: Yup.string().required({ key: "signup.required" }),
    sector_id: Yup.string().required({ key: "signup.required" }),
    employer: Yup.string().required({ key: "signup.required" }),
  });

  const registerPaymentSchema = Yup.object().shape({
    card_name: Yup.string().required({ key: "signup.required" }),
    card_number: Yup.string()
      .required({ key: "signup.required" })
      .test("regex", { key: "payment.cardNumber" }, (val: any) => {
        let regExp = new RegExp("^(\\d+$)");
        return val ? regExp.test(val) : true;
      }),
    year: Yup.number().required({ key: "signup.required" }),
    month: Yup.number().required({ key: "signup.required" }),
    cvv: Yup.string()
      .required({ key: "signup.required" })
      .test("regex", { key: "payment.cardNumber" }, (val: any) => {
        let regExp = new RegExp("^(\\d+$)");
        return val ? regExp.test(val) : true;
      })
      .max(3, { key: "payment.cvv" })
      .min(3, { key: "payment.cvv" }),
  });
  const contactUsValidationsSchema = Yup.object().shape({
    full_name: Yup.string().required({ key: "signup.required" }),
    family_name: Yup.string().required({ key: "signup.required" }),

    email: Yup.string()
      .test("regex", { key: "signup.matches" }, (val: any) => {
        let regExp = new RegExp("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}");
        return val ? regExp.test(val) : true;
      })
      .max(50, { key: "signup.emailLength" })
      .required({ key: "signup.required" }),
    message: Yup.string().required({ key: "signup.required" }),
  });

  const addArticleValidationsSchema = Yup.object().shape({
    ar_title: Yup.string().required({ key: "signup.required" }),

    en_title: Yup.string().required({ key: "signup.required" }),
    tags: Yup.string().required({ key: "signup.required" }),

    ar_content: Yup.string().required({ key: "signup.required" }),
    en_content: Yup.string().required({ key: "signup.required" }),
  });

  const addChatRoomSchema = Yup.object().shape({
    title: Yup.string().required({ key: "signup.required" }),
    members: Yup.array()
      .of(Yup.string())
      .required({ key: "signup.required" })
      // .test({
      //   message:(translate: any) => translate("signup.idLabel"),
      //   test: (arr : any) => arr.length > 0,
      // }),
      .test(
        "haveLength",
        { key: "signup.required" },
        (arr: any) => arr.length > 0
      ),
  });

  return {
    RegisterSchema,
    LoginSchema,
    updateProfileSchema,
    registerPaymentSchema,
    contactUsValidationsSchema,
    addArticleValidationsSchema,
    addChatRoomSchema,
    forgotPassSchema,
    resetPassSchema,
  };
}
