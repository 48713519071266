import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { store } from "../redux/store";
import { localStorageConstants } from "constants/appConstants";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use((request: any) => {
  // const token = store.getState().auth.token;
  const token = localStorage.getItem(localStorageConstants.TOKEN) ;
  if(token){
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  request.headers["language"] = store.getState().app.language;
  return request;
});
