import { instance } from "./baseInstance";

export const getUnseenNotifications = () => {
  return instance.get<any>("/unseen-notifications");
};

export const getNotifications = () => {
  return instance.get<any>("/notifications");
};

export const readAllNotifications = () => {
  return instance.post<any>("/read-all-notification");
};